import React from "react";
import { useParams, Redirect } from "react-router-dom";

const ChannelPage = () => {
  let { id, hash } = useParams();

  return <Redirect to={"/child/" + id + "/" + hash} />;
};

export default ChannelPage;
