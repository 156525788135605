import styled from "styled-components";

export const Lib = styled.div`
  padding: 20px;
  border: 1px solid #dedede;
  margin-bottom: 20px;
  background: #fff;
  text-align: center;

  h4 {
    text-transform: uppercase;
    font-size: 20px;
    margin: 10px;
    cursor: pointer;

    svg {
      font-size: 15px;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-column-gap: 30px;
    grid-row-gap: 10px;
  }
`;

export const LibPage = styled.div`
  img {
    width: 100%;
    border-radius: 50%;
    border: 1px solid #dedede;
  }

  img:hover {
    border: 3px solid #fff;
  }

  h3 {
    margin-top: 5px;
  }

  text-align: center;
  cursor: pointer;
`;
