import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import io from "socket.io-client";
import { createGlobalStyle } from "styled-components";

import AppContext from "./AppContext";
import { LoaderWrapper } from "./AppStyled";
import { FaCircleNotch } from "react-icons/fa";

import Home from "../../views/Home";
import Channel from "../../views/Channel";
import Teacher from "../../views/Teacher";
import Child from "../../views/Child";

const GlobalStyle = createGlobalStyle`

  html, body {
    touch-action: pan-y pan-x;
  }
  
  body {
    font-family: sans-serif;
    padding: 0;
    margin: 0;
    padding-top: 120px;
    background: #efefef;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }
`;

const socket = io(process.env.REACT_APP_API_URL, {
  autoConnect: false,
});

const App = () => {
  const [connected, setConnected] = useState(false);

  const socketConnect = () => {
    console.log("%c Socket connect", "color: green");
    setConnected(true);
  };

  const socketDisconnect = () => {
    console.log("%c Socket disconnect", "color: red");
    setConnected(false);
  };

  useEffect(() => {
    console.log("App mount");
    socket.connect();

    socket.on("connect", socketConnect);
    socket.on("disconnect", socketDisconnect);

    return () => {
      console.log("App unmount");

      socket.off("connect", socketConnect);
      socket.off("disconnect", socketDisconnect);
    };
  }, []);

  return (
    <>
      <GlobalStyle />
      <AppContext.Provider value={{ socket }}>
        {connected ? (
          <Router>
            <Switch>
              <Route path="/" exact component={Home} />

              <Route path="/child/:id/:hash" component={Child} />
              <Route path="/teacher/:id/:hash" component={Teacher} />

              <Route
                path="/channel/:teacherID/:teacherHash/:id/:hash"
                component={Channel}
              />

              <Route
                path="/child-trial/:id/:hash"
                component={() => <Child trial={true} />}
              />
              <Route
                path="/teacher-trial/:id/:hash"
                component={() => <Teacher trial={true} />}
              />
            </Switch>
          </Router>
        ) : (
          <LoaderWrapper>
            <p>
              <FaCircleNotch />
            </p>
            <p>Trwa łączenie...</p>
          </LoaderWrapper>
        )}
      </AppContext.Provider>
    </>
  );
};

export default App;
