import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(359deg); }
`;

export const Container = styled.div`
  margin: 0 20px;
  background: ${(bg) => (bg ? bg : "#fff")};
`;

export const Nav = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 10px;
  background: #fff;
  z-index: 9999;
  border-bottom: 1px solid #dedede;
  touch-action: manipulation;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const LoaderWrapper = styled.div`
  text-align: center;

  svg {
    font-size: 35px;
    animation: ${rotate} 2s infinite linear;
  }
`;
