import React from "react";
import { FaHome } from "react-icons/fa";

const Home = () => {
  return (
    <div style={{ fontSize: "30px", textAlign: "center" }}>
      <FaHome />
    </div>
  );
};

export default Home;
