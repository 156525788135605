import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import { FaTimes } from "react-icons/fa";
import styled, { css } from "styled-components";

const Grid = styled.div`
  display: grid;
  grid-row-gap: 10px;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  margin: 20px;
`;

const GridRow = styled.div`
  ${(props) =>
    props.fullscreen &&
    css`
      grid-column: 1 / -1;
      order: -1;
    `}
`;

const Teacher = ({ trial }) => {
  trial = trial ? 1 : 0;

  let { id, hash } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetch = () => {
      axios
        .get(
          process.env.REACT_APP_SYSTEM_URL +
            `/json/table/teacher?id=${id}&hash=${hash}&trial=${trial}`
        )
        .then(({ data }) => {
          if (data.length) {
            setData(data);
          } else {
            setError(true);
          }
        });
    };
    fetch();
    console.log("Teacher", trial);
  }, [id, hash, trial]);

  return (
    <>
      <h2 style={{ textAlign: "center", marginTop: "-100px" }}>
        Panel nauczyciela.
      </h2>
      {!!trial && (
        <>
          <h3 style={{ textAlign: "center" }}>(Zajęcia próbne)</h3>
        </>
      )}
      {!!error && (
        <p style={{ fontSize: "35px", textAlign: "center" }}>
          <FaTimes /> Nie znaleziono
        </p>
      )}
      <Grid>
        {data &&
          data.length &&
          data.slice(0, 20).map((child) => (
            <GridRow>
              <div style={{ textAlign: "center" }} key={child.id}>
                <a
                  href={
                    "http://" +
                    window.location.host +
                    (trial ? "/child-trial/" : "/child/") +
                    child.id +
                    "/" +
                    child.hash
                  }
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ color: "#000", display: "block", margin: "20px" }}
                >
                  {child.first_name}
                </a>
                <iframe
                  title={child.id}
                  src={
                    (trial ? "/child-trial/" : "/child/") +
                    child.id +
                    "/" +
                    child.hash
                  }
                  style={{ width: "100%", height: "600px", border: 0 }}
                />
              </div>
            </GridRow>
          ))}
      </Grid>
    </>
  );
};

export default Teacher;
