import styled, { css } from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.cols ? props.cols : "1fr")};
  max-width: 1100px;
  margin: 0px auto;
  margin-bottom: 10px;
  padding: 0 20px;
`;

export const Row = styled.div`
  position: relative;
  ::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    width: 3px;
    height: 100%;
    background: #d8d8d8;
    transform: translateX(-50%);
    z-index: -1;
  }
`;

export const Bead = styled.button`
  display: block;
  background: transparent;
  width: 75%;
  margin: 0 auto;
  height: 15px;
  padding: 0;
  border: 0;
  border-radius: 3px;
  margin-bottom: 5px;
  outline: none;

  @media only screen and (max-width: 600px) {
    height: 18px;
  }

  ${(props) =>
    props.type &&
    css`
      background: rgb(197, 42, 4);

      cursor: pointer;

      :hover {
        background: rgb(197, 35, 4);
      }
    `}
`;

export const Separator = styled.div`
  position: relative;
  height: 12px;
  width: 100%;
  background: #353535;
  border-top: 1px solid #616161;
  border-bottom: 1px solid #616161;
  margin-top: 5px;
  margin-bottom: 5px;

  ${(props) =>
    props.dot &&
    css`
      ::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #fff;
      }
    `}
`;
