import styled from "styled-components";

export const Wrapper = styled.div`
  canvas {
    border: 1px solid silver !important;
  }
`;

export const Button = styled.button`
  background: ${(props) => (props.active ? "#ff9901" : "#ffbd02")};
  border: 0;
  border-radius: 10px;
  color: ${(props) => (props.active ? "#fff" : "#252525")};
  padding: 8px;
  font-size: 18px;
  margin: 10px;
  margin-left: 0;
  cursor: pointer;
  outline: none;
  touch-action: manipulation;
`;

export const CanvasWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;

  transform: scale(${(props) => (props.zoom ? props.zoom : "1.0")});
  transform-origin: left top;

  .canvas-container {
    width: 95vw !important;
    height: 95vw !important;
    margin: 0 auto;

    background: #fff;

    canvas {
      touch-action: manipulation;
      pointer-events: ${(props) => (props.pan ? "none" : "inherit")};

      width: 95vw !important;
      height: 95vw !important;
    }

    img {
      width: 100%;
      user-select: none;
    }
  }
`;
